@font-face {
	font-family: 'Pretendard';
	src: url('./assets/fonts/Pretendard-T.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Pretendard';
	src: url('./assets/fonts/Pretendard-L.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Pretendard';
	src: url('./assets/fonts/Pretendard-R.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Pretendard';
	src: url('./assets/fonts/Pretendard-M.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Pretendard';
	src: url('./assets/fonts/Pretendard-SB.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Pretendard';
	src: url('./assets/fonts/Pretendard-B.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Pretendard';
	src: url('./assets/fonts/Pretendard-EB.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

body {
	margin: 0;
	font-family: 'Pretendard', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
